import React, { Component } from 'react';
import './ContactMain.css';

const ContactMain = () => {
	return (
		<div className='contact-main-container'>
			<div>
				<h2>Головной офис</h2>
				<a href="https://yandex.ru/maps/?um=constructor%3A70e47fbd56d87452777bca2fdfd98516ac01fdca73e9e4d0c3e8916653aca847&amp;source=constructorStatic" target="_blank"><img className='contact-map' src="https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A70e47fbd56d87452777bca2fdfd98516ac01fdca73e9e4d0c3e8916653aca847&amp;width=600&amp;height=450&amp;lang=ru_RU" alt="" style={{border: 0}} /></a>
			</div>

			<div className='contact-p-req'>
				<h2>Наши контакты и реквизиты</h2>
				<p>Юр.адрес: г.Алматы, ул.Бродского 37-А, кабинет 10.</p>
				<p>Факт.адрес: г.Алматы, ул.Бродского 37-А, кабинет 10.</p>
				<p>Тел. +7 777 008 08 78, +7 727 227 37 73</p>
				<p>Эл.адрес: forpost.kz10@mail.ru</p>
				<p>РНН 600500602592</p>
				<p>БИН 131140002751</p>
				<p>ИИК KZ 868560000006223711</p>
				<p>АГФ АО «Банк ЦентрКредит»</p>
				<p>БИК KCJBKZKX</p>
				<p>Директор: Бажимов Дос Омурбекович</p>
			</div>
		</div>
)
};

export default ContactMain;

