import React from 'react';
import './components.css';

const PartnerSub = () => {
	return (
		<div className='partner-sub-container'>
			<p className="partner-sub-container-p-1">Наши партнеры</p>
		</div>
)};

export default PartnerSub; 
