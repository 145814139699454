import React from 'react';
import './components.css';
import TrackingMain from './TrackingMain'

const HeroBlock = () => {
	return (
		<div className='hero-block-container'>
			<h1 className='text-shadow'>Доставка грузов по всему Казахстану</h1>
      {/* <h1 className='text-shadow'>по всему Казахстану</h1> */}
			<TrackingMain />
		</div>
)
};

export default HeroBlock; 
