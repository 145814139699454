import React from 'react';
import './Partner.css';
import Group from './components/media/Group.png'
import Exline from './components/media/Exline.png'
import Azimuth from './components/media/Azimuth.png'
import Gps from './components/media/Gps.png'
import Beta from './components/media/Beta.png'
import PartnerSub from './components/PartnerSub'

const Partner = () => {
	return (
		<div className='partner-container'>
			<PartnerSub />
      <img src={Group}/>
			<img src={Exline}/>
			<img src={Gps}/>
			<img src={Azimuth}/>
			<img className='hidden' src={Beta}/>
      {/* <PartnerSub /> */}
		</div>
)
};

export default Partner; 
