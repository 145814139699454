import React from 'react';
import './components.css';

const NavContactInfo = () => {
	return (
		<div className='sub-nav-properties nav-contact'>
			<span className='nav-contact-info'><a href="tel:+77770080878">+7 777 008 08 78</a></span>
		</div>
)};

export default NavContactInfo; 
